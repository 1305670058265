import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import { formatPrismicCountries } from "../utils/utilities"
import PublicRoute from "../routes/PublicRoute"

import CreateResellerAccount from "../components/CreateResellerAccount"

type Props = {|
  location: Object,
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const CreateResellerAdditionalInfoPage = ({
  location,
  pageContext,
  data,
}: Props) => {
  const lang = pageContext.unPublishedLang || pageContext.lang

  const {
    prismicLayout = {},
    prismicResellerAdditionalInfoPage = {},
    prismicUpdatePaymentDetailsPage = {},
  } = data || {}
  if (!data) {
    return null
  }

  // groom data of country names and codes
  const countriesData = prismicUpdatePaymentDetailsPage.data?.body[0]?.items
  const countries = countriesData ? formatPrismicCountries(countriesData) : []

  // groom data of states names and codes
  const statesData = prismicLayout.data?.body3 || []
  const statesOfCountries = statesData.reduce((result, countryData) => {
    const countryCode = countryData.primary.country_code.text
    if (countryCode) {
      // only display US and CA states for now
      if (countryCode !== "US" && countryCode !== "CA") return result
      result[countryCode] = countryData.items.map(stateData => ({
        name: stateData.state_code.text + " - " + stateData.state_name.text,
        value: stateData.state_code.text,
      }))
    }
    return result
  }, {})

  return (
    <PublicRoute
      component={() =>
        CreateResellerAccount({
          data: prismicResellerAdditionalInfoPage.data,
          countries,
          statesOfCountries,
          layoutData: prismicLayout.data,
          lang,
          location,
          magentoUserAdditionalInfo: true,
        })
      }
      lang={lang}
      location={location}
    />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutCreateAccountPage
    }
    prismicResellerAdditionalInfoPage(lang: { eq: $lang }) {
      ...ResellerAdditionalInfoPageFragment
    }
    prismicUpdatePaymentDetailsPage(lang: { eq: $lang }) {
      ...UpdatePaymentDetailsPage
    }
  }
`
export default withPreview(CreateResellerAdditionalInfoPage)
